import React from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Footer } from "../../components/footer"
import styled from "styled-components"
import { Container } from "../../theme/helpers"
import { colors, mediaQuery } from "../../theme"
import { Link } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { ReactComponent as CalendarSvg } from "../../images/callendar.svg"
import { ReactComponent as ArrowSvg } from "../../images/button-arrow.svg"

const PostContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: Manrope;
`
const PostBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .gatsby-image-wrapper {
    border-radius: 8px;
    width: 100%;
    height: 560px;
    margin-top: 72px;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        rgba(206, 229, 242, 0.3) 0%,
        rgba(84, 59, 227, 0.3) 100%
      );
    }

    ${mediaQuery("md")} {
      height: 200px;
      margin-top: 36px;
    }
    ${mediaQuery("lg")} {
      height: 400px;
      margin-top: 48px;
    }
  }
`
const Title = styled.h1`
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 125%;
  color: ${colors.black900};
  margin-top: 8px;

  ${mediaQuery("lg")} {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }
`

const Divider = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    #969bab -5.17%,
    rgba(150, 155, 171, 0) 101.83%
  );
  height: 2px;
  opacity: 0.4;
  margin: 48px 0;
`

const BackButton = styled(Link)`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-around;
  background: transparent;
  border: 1px solid #543be3;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 120px;
  transition: transform 0.25s ease-in-out;
  width: max-content;
  text-decoration: none;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  p {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-feature-settings: "liga" off;
    color: ${colors.blue900};
  }
  svg {
    transform: rotate(180deg);
    margin-right: 8px;
  }

  ${mediaQuery("lg")} {
    padding: 12px 14px;
    p {
      font-size: 14px;
      line-height: 24px;
    }
    svg {
      transform: rotate(180deg) scale(0.8, 0.8);
    }
  }
`

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-top: 48px;

  p {
    font-family: Manrope;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${colors.blue900};
    margin-left: 16px;
  }
`

const Content = styled.div`
  font-family: Manrope;
  font-size: 18px;
  line-height: 32px;
  color: ${colors.black800};
  margin: 64px 0 64px;
  text-align: justify;

  ul {
    list-style-type: disc;
    margin: 16px 0 16px 32px;

    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: 48px;
    }
  }
`
const BlogPost = ({ data }) => {
  const {
    wpPost: { title, content, featuredImage, date },
    wpPost,
  } = data

  return (
    <>
      <Seo post={wpPost} />
      <PostContainer>
        <PostBody>
          <Container>
            <GatsbyImage
              image={getImage(featuredImage?.node?.localFile)}
            ></GatsbyImage>
            <Date>
              <CalendarSvg />
              <p>{date}</p>
            </Date>
            <Title>{title}</Title>
            <Content dangerouslySetInnerHTML={{ __html: content }} />
            <Divider />
            <BackButton to="/">
              <ArrowSvg />
              <p> Grįžti į pagrindinį</p>
            </BackButton>
          </Container>
        </PostBody>

        <Footer />
      </PostContainer>
    </>
  )
}

export const query = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      date(formatString: "YYYY MMMM DD", locale: "lt")
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
export default BlogPost
